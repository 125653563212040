<template>
    <div class="main-container">
      <h5 class="pageTitle">발송 주기 수정/삭제 </h5>
      <form class="form-container" @submit.prevent="updatePeriod">
        <!-- 기체 관리명 -->
        <div class="form-group">
          <label>기체 관리명</label>
          <input
            class="input-box"
            type="text"
            :value="formData.itemName"
            disabled
          />
        </div>
        <!-- 템플릿 -->
        <div class="form-group">
          <label>템플릿</label>
          <select
            class="input-box"
            v-model="formData.template_ID"
          >
            <option
              v-for="template in templates"
              :key="template.uuid"
              :value="template.uuid"
            >
              {{ template.msg_name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>발송일</label>
        <div class="dropdown-container">
            <!-- 드롭다운 표시 영역 -->
            <div class="dropdown-display" @click="toggleDropdown">
                {{ selectedPeriodLabel || "발송일을 선택하세요." }}
            </div>

            <!-- 드롭다운 리스트 -->
            <div class="dropdown-list" v-if="isDropdownOpen">
                <div
                v-for="period in sendPeriods"
                :key="period.value"
                class="dropdown-item"
                @click="selectPeriod(period.value)"
                >
                {{ period.label }}
            </div>
           </div>
        </div>
     </div>

        <!-- 발송 시간 -->
        <div class="form-group">
          <label>발송 시간</label>
          <input
            class="input-box"
            type="time"
            v-model="formData.send_time"
          />
        </div>
        <!-- 버튼 -->
        <div class="button-container">
          <button
            type="button"
            class="button-list"
            @click="goToList"
          >
            목록
          </button>
          <button
            type="submit"
            class="button-register"
          >
            수정
          </button>
          <button
            type="button"
            class="button-delete"
            @click="deletePeriod"
          >
            삭제
          </button>
        </div>
      </form>
    </div>
  </template>
  
  
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import DailyCheckModule from "@/lib/DailyCheckModule";

  export default {
    props: {
      selectedRecord: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formData: {
          itemName: this.getItemName(this.selectedRecord?.item), // 기체 관리명 변환
          template_ID: this.selectedRecord?.template_ID || "",
          send_period: this.selectedRecord?.send_period?.toString() || "",
          send_time: this.selectedRecord?.send_time || "",
        },
        templates: [], // 서버에서 템플릿 데이터를 가져옵니다.
        sendPeriods: [
        { value: "1", label:  "1일전" },
        { value: "2", label:  "2일전" },
        { value: "3", label:  "3일전" },
        { value: "4", label:  "4일전" },
        { value: "5", label:  "5일전" },
        { value: "6", label:  "6일전" },
        { value: "7", label:  "7일전" },
        { value: "8", label:  "8일전" },
        { value: "9", label:  "9일전" },
        { value: "10", label: "10일전" },
        { value: "11", label: "11일전" },
        { value: "12", label: "12일전" },
        { value: "13", label: "13일전" },
        { value: "14", label: "14일전" },
        { value: "15", label: "15일전" },
        { value: "16", label: "16일전" },
        { value: "17", label: "17일전" },
        { value: "18", label: "18일전" },
        { value: "19", label: "19일전" },
        { value: "20", label: "20일전" },
        { value: "21", label: "21일전" },
        { value: "22", label: "22일전" },
        { value: "23", label: "23일전" },
        { value: "24", label: "24일전" },
        { value: "25", label: "25일전" },
        { value: "26", label: "26일전" },
        { value: "27", label: "27일전" },
        { value: "28", label: "28일전" },
        { value: "29", label: "29일전" },
        { value: "30", label: "30일전" },
        { value: "31", label: "31일전" },
        { value: "60", label: "60일전" },
        { value: "90", label: "90일전" },
      ],
      isDropdownOpen: false,
      };
    },
    computed: {
      senderNumber() {
        return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
      },
      selectedPeriodLabel() {
        const period = this.sendPeriods.find(
          (p) => p.value === this.formData.send_period
        );
        return period ? period.label : null;
      },
  },
    methods: {
        toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectPeriod(value) {
    this.formData.send_period = value; // 선택한 값 반영
    this.isDropdownOpen = false; // 드롭다운 닫기
  },
      getItemName(item) {
        switch (item) {
          case "0":
            return "보험";
          case "1":
            return "기체 안정성";
          case "2":
            return "비행승인";
          default:
            return "알 수 없음";
        }
      },
      goToList() {
        this.$router.push({ name: "sms-period-read" });
      },
      async updatePeriod() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          if (!token) {
            Swal.fire("인증 실패", "로그인이 필요합니다.", "warning");
            return;
          }

          const updateData = { ...this.formData };
          delete updateData.itemName;
          updateData.send_period=parseInt(updateData.send_period,10);

          await axios.put(
            `/sms-period/update_period/${this.selectedRecord.uuid}`,
            updateData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          await DailyCheckModule.recreateSmsData(this.senderNumber);
  
          Swal.fire("수정 성공", "발송 주기가 수정되었습니다.", "success");
          this.goToList();
        } catch (error) {
          Swal.fire(
            "수정 실패",
            error.response?.data?.message || "오류가 발생했습니다.",
            "error"
          );
        }
      },
      async deletePeriod() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
  
          if (!token) {
            Swal.fire("인증 실패", "로그인이 필요합니다.", "warning");
            return;
          }
  
          const confirm = await Swal.fire({
            title: "정말 삭제하시겠습니까?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
          });
          if (confirm.isConfirmed) {
            await axios.delete(`/sms-period/delete_period/${this.selectedRecord.uuid}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            
            await DailyCheckModule.recreateSmsData(this.senderNumber);

            Swal.fire("삭제 성공", "발송 주기가 삭제되었습니다.", "success");
            this.goToList();
          }
        } catch (error) {
          Swal.fire(
            "삭제 실패",
            error.response?.data?.message || "삭제 중 오류 발생",
            "error"
          );
        }
      },
      async fetchTemplates() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData ? tokenData.access_token : "";
          const response = await axios.get("/sms-period/read_templates", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.templates = response.data;
        } catch (error) {
          console.error("템플릿 데이터 조회 실패:", error);
        }
      },
    },
    mounted() {
      //console.log("Selected Record:", this.selectedRecord);
      this.fetchTemplates();
    },
  };
  </script>
  

   <style scoped>
   .main-container {
     padding: 20px;
     display: flex;
     flex-direction: column;
     align-items: center;
   }
   
   .pageTitle {
     font-weight: bold;
     margin-bottom: 40px;
     text-align: left;
     width: 100%;
   }
   
   .form-container {
     display: flex;
     flex-direction: column;
     gap: 15px;
     width: 70%;
     max-width: 800px;
   }
   
   .form-group {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     /* gap: 10px;
     flex-wrap: nowrap; */
     width: 100%;
   }
   
   .input-box {
     flex: 1;
     padding: 8px;
     margin-right: 40px;
     border: 1px solid rgba(130, 128, 128, 0.26);
     border-radius: 3px;
     max-width: 200px;
   }
   
   .checkbox-container {
     display: flex;
     flex-wrap: wrap;
     gap: 10px;
   }
   
   .checkbox-item {
     display: flex;
     align-items: center;
   }
   
   .checkbox-item input {
     margin-right: 5px;
   }
   
   .template-content {
     margin-top: 5px;
     font-size: 0.9em;
     color: #666;
   }
   
   .button-container {
     margin-top: 60px;
     display: flex;
     gap: 30px;
   }
   
   .button-list {
     background-color: #ff9800;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
   }
   
   .button-register {
     background-color: #4caf50;
     color: white;
     padding: 10px 20px;
     border: none;
     border-radius: 3px;
     cursor: pointer;
   }

   .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
   }
   .button-list:hover {
     background-color: #e68900;
   }
 
   .button-register:hover {
     background-color: #3e8e41;
   }
   
   .button-delete:hover {
     background-color: #b81004;
   }
   
   .dropdown-container {
     position: relative;
     display: inline-block;
   }
 
   .dropdown-display {
     padding: 8px;
     border: 1px solid #ccc;
     border-radius: 3px;
     cursor: pointer;
     min-width: 300px; /* 최소 너비 고정 */
     height: 40px; /* 고정 높이 */
     overflow: hidden; /* 텍스트가 길 경우 ... 처리 */
     white-space: nowrap; /* 한 줄로 처리 */
     text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 */
   }
 
   .dropdown-list {
     position: absolute;
     top: 100%;
     left: 0;
     right: 0;
     z-index: 1000;
     background-color: white;
     border: 1px solid #ccc;
     border-radius: 3px;
     max-height: 200px;
     overflow-y: auto;
   }
 
   .dropdown-item {
     padding: 8px;
     display: flex;
     align-items: center;
   }
 
   .dropdown-item input {
     margin-right: 5px;
   }
   .template-group {
     display: flex;
     gap: 20px;
   }
   .wide-input {
     min-width: 400px;
   }
   </style>
   